<template>
    <main class="flex-1 overflow-y-auto h-full">
        <PageHead sticky
                  title="Kennzeichnungspflichtige Inhaltsstoffe">
            <template #actions>
                <c-button :path="{ name: 'IngredientSubjectToLabelingAdd' }">Neu</c-button>
            </template>
        </PageHead>

        <List :items="ingredientsSubjectToLabeling"
              editRouteName="IngredientSubjectToLabelingEdit"/>

        <p class="p-6 text-center text-base text-gray-400">
            {{ ingredientsSubjectToLabeling.length }} Kennzeichnungspflichtige Inhaltsstoffe wurden geladen.
        </p>

    </main>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'IngredientsSubjectToLabeling',
    computed: {
        ...mapGetters('products', ['getIngredientsSubjectToLabeling']),
        ingredientsSubjectToLabeling() {
            return this.getIngredientsSubjectToLabeling();
        },
    },
};
</script>
